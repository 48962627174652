/* ---------------------- red --------------- */

label > input[type="checkbox"] + .red::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 25px;
  height: 25px;
  border-radius: 10%;
  border-style: solid;
  border-width: 0.15rem;
  border-color: #ff6150;
  margin-right: 15px;
  box-shadow: 0 0 5px #ff6150;
}

label > input[type="checkbox"] + .red::before {
  content: "×";
  font-weight: bold;
  font-size: 22px;
  transform: scale(1.1);
  color: #ff6150;
  text-align: center;
  background: white;
  border-color: #ff6150;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

label > input[type="checkbox"]:checked + .red {
  color: #ff6150;
}

/* ---------------------- green --------------- */
label > input[type="checkbox"] + .green::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 25px;
  height: 25px;
  border-radius: 10%;
  border-style: solid;
  border-width: 0.15rem;
  border-color: #35bfbf;
  margin-right: 15px;
  box-shadow: 0 0 5px #35bfbf;
}

label > input[type="checkbox"]:checked + .green::before {
  content: "✓";
  font-weight: bold;
  transform: scale(1.1);
  color: #35bfbf;
  text-align: center;
  background: white;
  border-color: #35bfbf;
  margin-right: 15px;
}

label > input[type="checkbox"]:checked + .green {
  color: #35bfbf;
}
