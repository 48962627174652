.custom-zoom-control {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .zoom-button {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
  }
  
  .zoom-button:hover {
    background-color: #f0f0f0;
  }

  .custom-marker {
    transform-origin: center;
  }