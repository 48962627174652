table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    padding: 8px;
    width: 22%;
    text-align: center;
    border-bottom: 1px solid #DDD;
  }
  
  /* tr:hover {background-color: #D6EEEE;} */