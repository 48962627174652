table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px;
  width: 22%;
  text-align: center;
  border-bottom: 1px solid #DDD;
}

/* tr:hover {
  background-color: #D6EEEE;
} */

.custom-toolbar {
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
  padding-top: 0px;
  margin-left: -10px;
  width: 100%;
  border-bottom: "0px";
}



.custom-editor {
  border: none;
  margin-left: -10px;
  padding: 8px;
  min-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
}

.custom-editor-container {
  border: none;
  /* min-height: 200px; */
}