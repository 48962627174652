.widget-sell .sale {
  background-color: #f5f6f9;
  width: 100%;
  padding: 17px;
  height: 180px;
}
.widget-sell .sale h3 {
  font-weight: 600;
  color: #1f2f3e;
  margin-bottom: 15px;
}
/* .widget-sell .sale h5 {
  font-weight: bold;
  font-size: 26px;
  color: #1f2f3e;
  margin-bottom: 20px;
} */
.widget-sell .sale p {
  color: lightgray;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  align-items: center !important;
}
.widget-sell .sale p span[class*="bg-"] {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.widget-sell .sale p span[class*="bg-"] svg {
  position: absolute;
  fill: #ff6150;
  padding-top: 0;
  width: 14px;
  height: 14px;
}
.widget-sell .sale span.sale-title {
  font-weight: 600;
  color: #848789;
  margin-left: 25px;
}
[dir="rtl"] .widget-sell .sale span.sale-title {
  margin-left: unset;
  margin-right: 25px;
}

.custom-dropdown-analytics {
  position: relative;
  display: inline-block;
  width: 350px;
}

.dropdown-toggle {
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 17px 14px;
  cursor: pointer;
}

.dropdown-toggle-content {
  flex-grow: 1;
}

.arrow-icon {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #666; /* Change color as needed */
  transition: transform 0.3s ease;
}

.arrow-icon.open {
  transform: rotate(180deg);
}

.selected-items {
  overflow: hidden;
}

.dropdown-options-analytics {
  width: 350px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 250px;
  overflow-y: auto;
}

.search-input {
  width: 95%;
  padding: 7px;
  margin: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.group-header {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  background-color: lightgray;
}

.group-header input[type="checkbox"] {
  margin-right: 5px;
}

.dropdown-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding: 10px;
}
.dropdown-item:hover {
  background-color: #e5e5e5;
}
.item input[type="checkbox"] {
  margin-right: 5px;
}

.selected {
  background-color: #f0f0f0;
}

.dropdown-options button {
  width: 50%;
  background-color: #ccc;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 7px;
  cursor: pointer;
  margin: 5px;
  outline: none;
}

.dropdown-options button:hover {
  background-color: #999;
}

.dropdown-options button:first-child {
  margin-right: 5px;
}
/* --------------- */

.custom-conneceted-servers-dropdown-analytics {
  position: relative;
  display: inline-block;
  width: 150px;
}

.conneceted-servers-dropdown-options-analytics {
  width: 150px;
  position: relative;
  top: 0;
  left: -200;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 250px;
  overflow-y: auto;
}
