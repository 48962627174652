/**=====================
  4.3 Update CSS Start
==========================**/
@import "../utils/variables";
// jsgrid css
.jsgrid-filter-row {
    select,
    input {
        padding: 6px 10px;
        border: 1px solid $horizontal-border-color;
        background: $white;
    }
}
.progress-number {
    button {
        &.btn {
            padding: 0.25rem 0.5rem !important;
        }
    }
}
.product-page-width {
    tbody {
        tr {
            td {
                padding: 0;
                border: none;
            }
        }
    }
}
.carousel .thumb.selected, .carousel .thumb:hover {
    border: none;
}
.checkbox .checkbox-icon {
    margin: 0 5px;
    vertical-align: middle;
}
.breadcrumb-colored {
    .breadcrumb {
        margin-bottom: 0;
    }
}
.disabled-lists {
    .list-group-item {
        img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }
    }
}
.profile-scroll {
    .vertical-scroll {
        .list-group {
            .list-group-item {
                img {
                    width: 60px;
                    height: 60px;
                    margin-right: 10px;
                }
            }
        }
    }
}
.form-floating {
    margin-bottom: 0 !important;
}
.react-calendar {
    width: unset;
    border: none;
    background: transparent;
}
.react-calendar__tile--active{
    background: $primary-color;
}
.radar-chartjs {
    canvas {
        height: 398px;
        width: 398px;
        margin-left: auto;
        margin-right: auto;
    }
}
.rdt_Pagination{
    > div{
      button{
        background: transparent;
      }
    }
}
.rdt_TableBody {
    button {
        background-color: transparent;
        padding: 0;
    }
}
// flex mixin
@mixin flex_component{
    display: flex;
    align-items: center;
    justify-content: center;
}
span.twitter-typeahead {
    .league-name {
      font-size: 16px;
      padding: 6px 10px 0;
    }
    .tt-menu {
      float: left;
      width: 100%;
      min-width: 10rem;
      margin: 0.125rem 0 0;
      font-size: 1rem;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: $white;
      background-clip: padding-box;
      border-radius: 0.25rem;
      -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
      box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
      border: none;
      padding: 0;
    }
    .tt-suggestion {
      color: $dark-editor-document;
      opacity: 0.6;
      font-size: 13px;
      padding: 6px 12px;
      border-top: 1px solid $light-semi-gray;
      background: $white;
      cursor: pointer;
      &:hover,
      &:focus {
        background-color: $white;
      }
      &.active,
      &:active {
        color: $white;
        background-color: var(--theme-deafult);
      }
    }
  }
// popover
.popover {
    background-color: $white;
    border: none;
    -webkit-box-shadow: 0 0 20px rgba($primary-color, 0.1);
    box-shadow: 0 0 20px rgba($primary-color, 0.1);
    .popover-header {
        background-color: $theme-medium-color;
        color: $primary-color;
        border-bottom: none;
    }
    .popover-body {
        color: rgba(43, 43, 43, 0.7);
    }
}
.collapse-horizontal {
    .card-body {
        width: 350px;
    }
}
.dark-accordion {
    color: $dark-all-font-color;
}
.blockquote {
    > :last-child {
      margin-top: 0;
    }
}
// tooltip css start
.tooltip {
    &.bs-tooltip-top {
        .tooltip-arrow {
            &:before {
                border-top-color: $theme-medium-color;
            }
        }
    }
    &.bs-tooltip-bottom {
        .tooltip-arrow {
            &:before {
                border-bottom-color: $theme-medium-color;
                border-top-color: transparent;
            }
        }
    }
    &.bs-tooltip-start {
        .tooltip-arrow {
            &:before {
                border-left-color: $theme-medium-color;
                border-top-color: transparent;
            }
        }
    }
    &.bs-tooltip-end {
        .tooltip-arrow {
            &:before {
                border-right-color: $theme-medium-color;
                border-top-color: transparent;
            }
        }
    }
    .tooltip-inner {
        background-color: $theme-medium-color;
        color: $primary-color;
    }
    .tooltip-arrow {
        &:before {
            border-top-color: $theme-medium-color;
        }
    }
}
// tooltip css end
// dropdown css start
.dropdown-basic {
    .dropdown {
        .dropdown-content {
            a {
                padding: 6px 16px;
                color: $dark-editor-document;
                opacity: 0.6;
                font-size: 13px;
                border-top: 1px solid $light-semi-gray;
                background: $white;
                &:hover {
                    background-color: $white;
                }
            }
            .dropdown-header {
                padding: 8px 16px;
                font-weight: 400;
                color: $dark-color;
                font-size: 13px;
            }
        }
    }
    .dropup {
        .dropup-content {
            top: auto;
        }
    }
}
// dropdown css end
// accordian css start
.default-according {
    .card {
        .card-header {
            padding: 0;
            border: none;
            border-radius: 0;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            &.bg-primary,
            &.bg-secondary {
                .btn {
                    border-color: $transparent-color;
                }
            }
            i {
                position: initial;
                font-size: 20px;
                display: inline-block;
                vertical-align: text-bottom;
                margin-right: 5px;
                [dir="rtl"] & {
                    margin-right: unset;
                    margin-left: 5px;
                }
            }
            h5 {
                margin-top: 0;
            }
            .btn-link {
                padding: 12px 20px;
                width: 100%;
                text-align: left;
                letter-spacing: 0.7px;
                font-weight: 600;
                border: 1px solid $light-semi-gray;
                border-radius: 0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                [dir="rtl"] & {
                    text-align: right;
                }
                @media (max-width: 575px) {
                    padding: 12px 15px;
                }
            }
        }
        .card-body {
            padding: 15px 20px;
            line-height: 22px;
            font-size: 13px;
            border-color: $light-semi-gray;
            border-radius: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            color: $dark-gray;
            font-weight: 500;
        }
    }
}
// accordian css end
// tab-bootstrap css start
.nav-tabs {
    border-bottom-color: $light-semi-gray;
    .nav-bottom {
        .nav-item {
            .nav-link.active {
                border-color: $light-semi-gray;
            }
        }
    }
}
.nav-tabs {
    .nav-item {
        &.show {
            .nav-link {
                font-weight: 500;
            }
        }
    }
    .nav-link {
        color: $dark-color;
        &.active {
            font-weight: 500;
        }
    }
    &.nav-bottom {
        .nav-item {
            .nav-link {
                &.active {
                    border-color: $light-gray $light-gray #fff;
                }
            }
        }
    }
}
.btn-close {
    &:focus-visible {
        outline: none !important;
    }
    &:focus {
        box-shadow: none !important;
    }
}
.dropdown-menu {
    -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    border: none;
    padding: 0;
    z-index: 1;
    .dropdown-item {
        color: #2b2b2b;
        opacity: 0.6;
        font-size: 13px;
        padding: 6px 12px;
        border-top: 1px solid $light-semi-gray;
        background: $white;
        &:hover {
            background-color: $white;
        }
    }
    li {
        &:first-child {
            .dropdown-item {
                border-top: unset;
            }
        }
    }
    .dropdown-divider {
        margin: 0;
        border-top: 1px solid #cccccc;
    }
}
// tab-bootstrap css end
.border-tab {
    .nav-tabs {
        .nav-item {
            .nav-link {
                &.active {
                    border-bottom: 2px solid $primary-color;
                }
                &.show {
                    border-bottom: 2px solid $primary-color;
                }
                &:focus {
                    border-bottom: 2px solid $primary-color;
                }
            }
        }
    }
}
.tabbed-card {
    .nav-tabs {
        .nav-item {
            .nav-link {
                top: 17px;
            }
        }
    }
}
.timeliny {
    .timeliny-dot::before {
        font-size: $btn-lg-font-size;
    }
}
.component {
    .input-group {
        .btn {
            line-height: 32px;
            text-transform: capitalize;
        }
    }
}
.login-card {
    .login-main {
        .theme-form {
            label {
                font-size: $body-font-size;
            }
            .show-hide {
                top: 50%;
            }
        }
    }
}
// faq css start
.faq-accordion {
    .card {
        .btn-link {
            svg {
                margin-left: 20px;
                @media (max-width: 575.98px) {
                    margin-left: 10px;
                }
            }
        }
    }
}
// faq css end
.job-filter {
    .faq-form {
        .form-control {
            font-size: 14px;
        }
        .search-icon {
            width: 16px;
            height: 16px;
        }
    }
}
.toast-container {
    position: unset;
}
// editor css
button[data-toggle="dropdown"] {
    &:hover {
        ~ {
            .dropdown-menu {
                display: block;
                min-width: 300px;
                top: 35px;
            }
        }
    }
}
// owl-carousel css
.carousel {
    .carousel-indicators {
        margin-bottom: 3px;
    }
}
.mouse-wheel {
    .owl-stage-outer {
        .owl-stage {
            width: 5600px !important;
            .owl-item {
                width: 225.333px !important;
            }
        }
    }
}
.carousel-item {
    .carousel-caption {
        width: 100%;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 26px 10px;
        background-color: rgba($dark-card-background, 0.51) !important;
    }
    .carousel-opacity {
        background-color: rgba($light-color, 0.51);
    }
}
.carousel {
    .carousel-control-prev-icon {
        width: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
        height: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
    }
    .carousel-control-next-icon {
        width: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
        height: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
    }
}
// Rating 
.rating-header {
    h4 {
        [dir="rtl"] & {
            direction: ltr;
        }
    }
}
// Animated modal
.animate-img {
    .animate-widget {
        img {
            width: 100%;
            height: 350px;
            margin: 0 auto;
            object-fit: cover;
        }
    }
}
.modal-popup {
    &.modal {
        top: 20%;
        left: 50%;
        transform: translate(-50%, 0);
        .modal-dialog {
            .theme-close {
                width: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
                height: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }
}
.animated-modal {
    .form-select {
        @media (max-width:442px) {
            width: 100% !important;
        }
    }
}
// TO-DO scss
.task-container {
    .badge {
        line-height: 13px;
    }
}
// starter kit
.starter-main {
    .alert-primary {
        &.inverse {
            padding: 13px 20px 13px 65px;
            border-color: $primary-color;
            color: $dark-color;
            [dir="rtl"] & {
                padding: 13px 65px 13px 20px;
            }
            &:before {
                content: "";
                position: absolute;
                left: 54px;
                width: 0;
                height: 0;
                border-left: 7px solid $primary-color;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                [dir="rtl"] & {
                    border-right: 7px solid $primary-color;
                    border-left: unset;
                }
            }
            i {
                padding: 17px 20px;
                display: flex;
                align-items: center;
                background-color: $primary-color;
                color: $white;
                border-radius: 3px 0 0 3px;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                [dir="rtl"] & {
                    right: 0;
                    left: unset;
                }
            }
        }
    }
}
// map-js 
.map-z-index {
    z-index: 1;
}

// dashboard tables
.order-wrapper{
    .btn{
        background-color: $light-color;
    }
}

.recent-wrapper{
    .btn{
        width: 103px;
    }
}

// User Card css
.user-card-wrapper{
    .social-profile{
        background-image: unset;
        .social-follow{
            justify-content: center;
        }
    }
}


/**=====================
    4.3 Update CSS Ends
==========================**/