.skeleton-component {

    background-color: #f2f2f2;
    /* Gray background color */
    border-radius: 4px;
    /* Optional: Add border radius for rounded corners */
    animation: skeleton-component-animation 1.5s linear infinite alternate;
    /* Animation */
}

@keyframes skeleton-component-animation {
    0% {
        background-color: #f2f2f2;
        /* Initial gray color */
    }

    100% {
        background-color: #d2d2d2;
        /* Lighter gray color */
    }
}