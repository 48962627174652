.sticky-bar-operations {
    position: fixed;
    top: 50vh;
    /* Adjust based on your needs */
    right: 0;
    width: 38px;
    /* Adjust based on your needs */
    background: #f8f9fa;
    border: 2px solid blue;
    z-index: 1000;
    margin-right: 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.sticky-label {
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid #007bff;
}

.sticky-label.active {
    color: #007bff;
    /* background: white; */
}

.sticky-label:hover {
    color: #007bff;
    /* background: white; */
}

.sticky-label .tooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    right: 30px;
    /* Position the tooltip to the left of the text */
    transform: translateY(-95%);
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap;
    /* Ensure the tooltip text doesn't wrap */
}

.sticky-label:hover .tooltip {
    visibility: visible;
    opacity: 1;
}