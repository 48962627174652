.sticky-bar {
    position: fixed;
    top: 45vh;
    /* Adjust based on your needs */
    right: 0;
    width: 38px;
    /* Adjust based on your needs */
    background: #f8f9fa;
    border: 2px solid blue;
    z-index: 1000;
    margin-right: 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.sticky-label {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid #007bff;
}

.sticky-label.active {
    color: #007bff;
    /* background: white; */
}