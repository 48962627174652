.global-table-delete-btn{
    width: 60px;
    font-size: 14px;
    padding: 3px;
    color: white;
    background-color: red;
    border-radius: 5px;
    margin-right: 5px;
    border: none;
}
.global-table-edit-btn{
    width: 60px;
    font-Size: 14px;
    padding: 3px;
    color: white;
    background-color: green;
    border-radius: 5px;
    border: none;

}
.global-search-field{
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 12px;
    margin: 15px;
    height:40px;
    width: calc(100% - 30px); 
    border: none;
    outline: none; 
}