table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px;
  width: 22%;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

/* tr:hover {
  background-color: #d6eeee;
} */

.dropdown-toggle-button {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  padding: 0.375rem 0.75rem;
  z-index: 1; /* Set z-index for the dropdown toggle button */
}

.dropdown-menu {
  padding: 0.5rem 0;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  z-index: 2; /* Set z-index for the dropdown menu */
}

.dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
}

.ant-btn-primary {
  background-color: green !important;
}
